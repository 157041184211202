import React, { useState, useEffect } from 'react'
import { buildImageObj } from '../../lib/helpers'
import { imageUrlFor } from '../../lib/image-url'
import PageTrans from '../page-trans/page-trans'
import * as styles from './list-Item.module.scss'

const checkFocus = (focusOn, setFocusOn, slug) => {
  return () => {
    const target = document.querySelector(`[data-listed-item="${slug}"]`)
    if (!target) { return }
    const box = target.getBoundingClientRect()
    const height = window.innerHeight
    const top = box.top
    const bottom = box.bottom

    if (top > 0 && bottom < height) {
      setFocusOn(true)
    } else {
      setFocusOn(false)
    }
  }
}

const ListItem = ({ item, index, count }) => {
  if (!item) { return }
  const [focusOn, setFocusOn] = useState(false)
  const image = item.mainImage || item._rawMainImage

  useEffect(() => {
    window.addEventListener('scroll', checkFocus(focusOn, setFocusOn, item.slug.current), { passive: true })

    return () => {
      window.removeEventListener('scroll', checkFocus())
    }
  }, [])

  const catArray = item.categories?.map((cat) => cat.title)

  return (
    <div className={`${styles.item} ${focusOn ? styles.itemInFocus : ''}`} data-listed-item={item.slug.current}>
      <PageTrans to={`/${item.slug.current}`} fade >
        <span className={styles.itemIndex}>
          <span>{index + 1 < 10 ? `0${index + 1}` : index + 1}</span>
        </span>

        <div className={styles.itemMain} data-listed-item>
          <div className={styles.itemImageHolder}>
            <div className={styles.itemImage} style={{ backgroundImage: `url(${imageUrlFor(buildImageObj(image)).width(800).fit('fill').url()})` }} aria-label={image.alt} />
          </div>

          <div className={styles.itemContentHolder}>
            <div className={styles.itemContent}>
              {catArray?.length > 0 && <span>{catArray?.join(', ')}</span>}
              <h3>{item.shortTitle || item.title}</h3>

              <div className={styles.itemHidden}>
                {item.excerpt?.length > 0 && <p>{item?.excerpt}</p>}

                <button>More Info</button>
              </div>
            </div>
          </div>
        </div>
      </PageTrans>
    </div>
  )
}

export default ListItem
