import React, { useState, useEffect, useContext } from 'react'
import Layout from '../../containers/layout'
import BlockContent from '../block-content/block-content'
import HomeSplash from '../home-splash/home-splash'
import ListingItems from '../listing-items/listing-items'
import SiteContext from '../site-context/site-context'
import ZigZag from '../zigzag/zigzag'
import * as styles from './home.module.scss'

const rotateHeadings = (headings, initCycle, setHeading) => {
  const wordDelay = 2500
  let index = 0

  const changeHeading = () => {
    index++
    if (index >= headings.length) { index = 0 }
    const currentHeading = headings[index]

    setTimeout(() => {
      setHeading(currentHeading)
      changeHeading()
    }, wordDelay)
  }

  changeHeading()
}

const Home = ({ home }) => {
  const headings = home.rotatingHeadings
  const {splash: { splashPlayed }} = useContext(SiteContext)
  const [initCycle, setInitCycle] = useState(false)
  const [heading, setHeading] = useState(headings[0])
  const [headingChange, setHeadingChange] = useState(false)
  const introAnimation = splashPlayed ? 0 : 1700

  if (headings !== null) {
    useEffect(() => {
      setInitCycle(true)
      setTimeout(() => {
        rotateHeadings(headings, initCycle, setHeading)
      }, introAnimation + 1500)
    }, [])
  }

  return (
    <div className={styles.homeWrapper}>
      <HomeSplash length={introAnimation} />
      <div className={styles.homeContent} data-animate-in>
        <h1 data-title-heading className={`${styles.homeTitle} ${!headingChange ? '' : styles.homeTitleChange}` } dangerouslySetInnerHTML={{ __html: heading.replace(/~/g, '<br />') }} />

        <div className={styles.homeIntro}>
          <BlockContent blocks={home._rawIntroBlurb} />
        </div>

        <div className={styles.zigzagHolder}>
          <ZigZag />
        </div>
      </div>

      <div className={styles.homeList}>
        <ListingItems items={home._rawSections} />
      </div>
    </div>
  )
}

export default Home
