// extracted by mini-css-extract-plugin
export var hide = "home-module--hide--DMIIh";
export var allHide = "home-module--all--hide--FSp1q";
export var smallHide = "home-module--small--hide--YtBtQ";
export var mediumHide = "home-module--medium--hide--sPvLh";
export var mediumDownHide = "home-module--medium-down--hide--+QnPt";
export var mediumUpHide = "home-module--medium-up--hide--DgZdF";
export var largeHide = "home-module--large--hide--hk0bN";
export var xlargeHide = "home-module--xlarge--hide--QaG7S";
export var xxlargeHide = "home-module--xxlarge--hide--X8dXp";
export var homeWrapper = "home-module--homeWrapper--bZ7J5";
export var homeContent = "home-module--homeContent--IiCWT";
export var homeList = "home-module--homeList--0lav6";
export var homeTitle = "home-module--homeTitle--G1rLc";
export var homeTitleChange = "home-module--homeTitleChange--I3sqF";
export var change = "home-module--change--PL-VO";
export var homeIntro = "home-module--homeIntro--KG5JA";
export var zigzagHolder = "home-module--zigzagHolder--29+BK";