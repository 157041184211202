import React from 'react'
import ListItem from '../listItem/list-item'
import * as styles from './listing-items.module.scss'

const ListingItems = ({ items }) => {
  return (
    <div className={styles.listingGrid} data-animate-in>
      {
        items.map((item, index) => {
          return (
            <ListItem item={item} index={index} count={items.length} key={`listed-item--${index}`}/>
          )
        })
      }
    </div>
  )
}

export default ListingItems
