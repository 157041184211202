import React from 'react'
import { graphql } from 'gatsby'
import SEO from '../components/seo/seo'
import Layout from '../containers/layout'
import Home from '../components/home/home'

export const query = graphql`
  query IndexPageQuery {
    site: sanitySiteSettings {
      keywords
      title
      description
    }

    home: sanityHomepage {
      rotatingHeadings
      _rawIntroBlurb
      _rawSections(resolveReferences: {maxDepth: 10})
    }
  }
`

const IndexPage = props => {
  const { data, errors } = props
  const { site, home } = data

  return (
    <Layout>
      <SEO title={site?.title} description={site?.description} keywords={site?.keywords} />

      <Home home={home} />
    </Layout>
  )
}

export default IndexPage
